export const HTTP_STATUS = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  EARLY_HINTS: 103,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  MOVED_TEMPORARILY: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  REQUEST_TOO_LONG: 413,
  REQUEST_URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  INSUFFICIENT_SPACE_ON_RESOURCE: 419,
  METHOD_FAILURE: 420,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  INSUFFICIENT_STORAGE: 507,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
  OTHER: -1,
}

export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
}

interface ErrorMessage {
  [key: string]: string
}

export const ERROR_MESSAGE: ErrorMessage = {
  UPDATE_FAIL: 'Cập nhật thất bại',
  BAD_REQUEST: 'Yêu cầu không hợp lệ',
  FILE_TOO_BIG: 'Tệp quá lớn',
  ROLE_NOT_FOUND: 'Không tìm thấy vai trò',
  USER_NOT_FOUND: 'Không tìm thấy người dùng',
  USER_IS_EXISTED: 'Người dùng đã tồn tại',
  USER_IS_NOT_VERIFIED: 'Người dùng chưa được xác minh',
  UNAUTHORIZED: 'Không được phép truy cập',
  FORBIDDEN: 'Bị từ chối truy cập',
  THIS_ACTION_FOR_ADMIN: 'Hành động này chỉ dành cho quản trị viên',
  AUTH_ADMIN_OR_COORDINATOR: 'Chỉ có quản trị viên hoặc người phối hợp mới được phép truy cập',
  STORE_NOT_FOUND:'Không tìm thấy cửa hàng',
  PERMISSION_DENIED: 'Không được cấp quyền truy cập',
  ACCOUNT_INACTIVE: 'Tài khoản không hoạt động',
  AUTH_ADMIN: 'Chỉ có quản trị viên mới được phép truy cập',
  PALLET_NOT_FOUNT_IN_STORE: 'Không tìm thấy pallet trong cửa hàng',
  INVOICE_NOT_FOUND: 'Không tìm thấy hóa đơn',
  DUPLICATE_STORE: 'Cửa hàng trùng lặp',
  FILES_LIMIT: 'Vượt quá giới hạn số tệp',
  INVALID_OBJECTID: 'ID đối tượng không hợp lệ',
  INVALID_PALLET: 'Pallet không hợp lệ',
  FILE_LIMIT_SIZE: 'Vượt quá giới hạn kích thước tệp',
  INCORRECT_FILE: 'Tệp không đúng định dạng',
  INVALID_PRODUCT: 'Sản phẩm không hợp lệ',
  INVALID_INVENTORY: 'Hàng tồn kho không hợp lệ',
  OUT_OF_SKU: 'Hết SKU',
  NOT_ENOUGH_SKU: 'SKU không đủ',
  DUPLICATE_SKU: 'SKU trùng lặp',
  INVALID_INVOICE: 'Hóa đơn không hợp lệ',
  INVALID_STORE: 'Cửa hàng không hợp lệ',
  INVALID_TARGET_PALLET: 'Pallet đích không hợp lệ',
  NOT_ENOUGH_QUANTITY: 'Số lượng không đủ',
  DUPLICATE_INVENTORY: 'Hàng tồn kho bị trùng lặp',
  INVENTORY_INVALID_QUANTITY: 'Số lượng hàng tồn kho không hợp lệ',
  INVALID_QUANTITY: 'Số lượng không hợp lệ',
  INVALID_LOCATION: 'Địa điểm không hợp lệ',
  PRODUCT_NOT_FOUND: 'Không tìm thấy sản phẩm',
  EXISTING_STORE: 'Cửa hàng đã tồn tại',
  DUPLICATE_STORE_NAME: 'Tên cửa hàng trùng lặp',
  SKU_NOT_FOUND: 'Không tìm thấy SKU',
  STORE_NOT_IN_LOCATION: 'Cửa hàng không thuộc địa điểm này',
  NOT_ALLOW_CANCEL_INVOICE: 'Không được phép hủy hóa đơn',
  PALLET_NOT_FOUND_IN_LOCATION: 'Không tìm thấy pallet tại địa điểm này',
  PRODUCT_ALREADY_CONFIRMED: 'Sản phẩm đã được xác nhận',
  INVENTORY_NOT_FOUND_IN_LOCATION: 'Kho Không tồn tại ở vị trí này',
  PALLET_WAS_LOCKED: "Vị trí thêm tồn đang trong đơn hàng, vui lòng thử lại sau.",
  MALFORMED_FILE: "File không hợp lệ"
}

export const URLs = {}
